import React, { useState } from "react";
import { FlashCard } from "../../classes/FlashCard";
import "./MainCard.css";

interface MainCardProps {
  card: FlashCard;
}

const MainCard = (props: MainCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const card = props.card;

  const handleClick = () => {
    setIsOpen((c) => !c);
  };

  return (
    <div className="mainCard" onClick={handleClick}>
      <h2>{isOpen ? card.answer : card.question}</h2>
    </div>
  );
};

export default MainCard;
