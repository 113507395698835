import React from "react";
import { NavLink, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "./Pages/home/HomePage";
import StudyPage from "./Pages/study/StudyPage";
import LoginPage from "./Pages/login/LoginPage";
import SigninPage from "./Pages/signin/SigninPage";
import ProfilePage from "./Pages/profile/ProfilePage";
import CreateSetPage from "./Pages/createSet/CreateSetPage";
import { useAuth } from "./AuthContext";
import UpdateUserPage from "./Pages/updateUser/UpdateUserPage";
import { ENDPOINTS } from "./endpoint";

import "./App.css";
import ProfileBubble from "./Pages/home/ProfileBubble";
import UpdateSetPage from "./Pages/updateSet/UpdateSetPage";
import MainFooter from "./MainFooter";
import AboutUsPage from "./Pages/Others/aboutUs/AboutUsPage";
import PrivacyPolicyPage from "./Pages/Others/privacyPolicy/PrivacyPolicyPage";
import TermsOfServicePage from "./Pages/Others/termsOfService/TermsOfServicePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <header className="MainHeader">
        <h2 className="logo">
          <NavLink to={ENDPOINTS.HOME} className="HomeButton">
            <FontAwesomeIcon icon={faBook} className="logoIcon" />
            Dchup
          </NavLink>
        </h2>
        <ProfileBubble loggedIn={isAuthenticated} />
      </header>
      <div className="container">
        <Routes>
          <Route path={ENDPOINTS.HOME} element={<HomePage />} />
          <Route path={ENDPOINTS.STUDY} element={<StudyPage />} />
          <Route path={ENDPOINTS.LOGIN} element={<LoginPage />} />
          <Route path={ENDPOINTS.SIGNIN} element={<SigninPage />} />
          <Route path={ENDPOINTS.PROFILE} element={<ProfilePage />} />
          <Route path={ENDPOINTS.CREATE_SET} element={<CreateSetPage />} />
          <Route path={ENDPOINTS.UPDATE_USER} element={<UpdateUserPage />} />
          <Route path={ENDPOINTS.UPDATE_SET} element={<UpdateSetPage />} />
          <Route path={ENDPOINTS.ABOUT_US} element={<AboutUsPage />} />
          <Route path={ENDPOINTS.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
          <Route path={ENDPOINTS.TERMS_OF_SERVICE} element={<TermsOfServicePage />} />
        </Routes>
      </div>
      <MainFooter />
    </Router>
  );
}

export default App;
