import { useEffect, useState } from "react";
import validate_email, { validation_schema } from "../../functions/API/emailValidation";
import isPasswordValid from "../../functions/etc/validatePassword";
import signIn from "../../functions/API/sign_in";
import { useNavigate } from "react-router-dom";
import "./signinPage.css";

const SigninPage = () => {
  const navigator = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [validPW, setValidPW] = useState<string>("");
  const [validationMsg, setValidationMsg] = useState<string>("");
  const [vdLoading, setVdLoading] = useState<boolean>(false);

  useEffect(() => {
    setValidEmail(false);
    setValidationMsg("");
  }, [email]);

  useEffect(() => {
    setValidPW(isPasswordValid(password));
  }, [password]);

  const handleValidation = async () => {
    try {
      const response = await validate_email(email);
      if (response.ok) {
        const data: validation_schema = await response.json();
        setValidEmail(data.is_valid);
        setValidationMsg(data.detail);
      } else {
        setValidationMsg("An error occurred while validating the email.");
      }
    } catch (error) {
      setValidationMsg("An error occurred while validating the email.");
    }
  };

  const handleSignIn = async () => {
    const result = await signIn({ email, password });
    if (result) {
      console.log("Successfully created account");
      navigator("/login/");
    } else {
      console.log("Something went wrong");
    }
    return;
  };

  const handleCancel = () => {
    navigator("/");
  };

  return (
    <div className="signin-container">
      <h1 className="signin-header">Sign In</h1>
      <input
        type="text"
        value={email}
        className="signin-input"
        placeholder="Enter your email"
        onChange={(e) => setEmail(e.target.value)}
      />
      {!validEmail && (
        <button
          className="validate-button"
          onClick={() => {
            if (email) {
              setVdLoading(true);
              handleValidation().then(() => setVdLoading(false));
            }
          }}
        >
          {vdLoading ? "Loading" : "Validate your email"}
        </button>
      )}
      <p className="validation-msg">{validationMsg}</p>
      <input
        type="password"
        value={password}
        className="signin-input"
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <p className="validation-msg">
        {validPW === "ok" ? "Your password is ok" : validPW}
      </p>
      <input
        type="password"
        value={confirmPassword}
        className="signin-input"
        placeholder="Confirm your password"
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <p className="validation-msg">
        {password === confirmPassword || confirmPassword === ""
          ? null
          : "Passwords do not match"}
      </p>
      <div className="button-container">
        <button
          className="signin-button"
          onClick={handleSignIn}
          disabled={!(validEmail && password === confirmPassword && validPW === "ok")}
        >
          Sign In
        </button>
        <button className="cancel-button" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SigninPage;
