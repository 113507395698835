import React, { useEffect, useState } from "react";
import { CardGet, CardsetGet } from "../../interfaces/CardsetGet";
import { shuffle } from "../../functions/etc/shuffleArray";

import "./WriteStudy.css";

interface Props {
  cardset: CardsetGet;
  cards: CardGet[];
  setMod: React.Dispatch<React.SetStateAction<string>>;
}

const WriteStudy: React.FC<Props> = ({ setMod, cards, cardset }) => {
  const TotalCards = cards.length;
  const [remainingCards, setRemainingCards] = useState<CardGet[]>(cards);
  const [doneCards, setDoneCards] = useState<CardGet[]>([]);
  const [wrongCards, setWrongCards] = useState<CardGet[]>([]);
  const [finished, setFinished] = useState<boolean>(false);
  const [mastered, setMastered] = useState<boolean>(false);
  const [roundRecords, setRoundRecords] = useState<number[]>([]);
  const [answer, setAnswer] = useState<string>("");
  const [answered, setAnswered] = useState<boolean>(false);
  const [answerWith, setAnswerWith] = useState<"question" | "answer">("answer");
  const [settingsOn, setSettingsOn] = useState<boolean>(false);

  useEffect(() => {
    if (remainingCards.length === 0) {
      setFinished(true);
      if (wrongCards.length === 0) {
        setMastered(true);
        setRemainingCards(shuffle(cards));
        setDoneCards([]);
        setWrongCards([]);
      }
    }
  }, [remainingCards, wrongCards, doneCards, cards]);

  const displayedText = (): string =>
    answerWith === "question"
      ? remainingCards[0]?.answer || ""
      : remainingCards[0]?.question || "";

  const answerText = (): string =>
    answerWith === "question"
      ? remainingCards[0]?.question || ""
      : remainingCards[0]?.answer || "";

  const handleExit = () => {
    setMod("base");
  };

  const handleRestart = () => {
    setRemainingCards(shuffle(cards));
    setDoneCards([]);
    setWrongCards([]);
    setFinished(false);
    setMastered(false);
    setSettingsOn(false);
  };

  const handleNextRound = () => {
    setRemainingCards(shuffle(wrongCards));
    setWrongCards([]);
    setFinished(false);
    setRoundRecords((prev) => [...prev, doneCards.length]);
  };

  const handleNext = () => {
    if (answer.trim() === answerText().trim()) {
      setDoneCards((prev) => [...prev, remainingCards[0]]);
    } else {
      setWrongCards((prev) => [...prev, remainingCards[0]]);
    }
    setRemainingCards((prev) => prev.slice(1));
    setAnswered(false);
    setAnswer("");
  };

  const handleAnswer = () => {
    setAnswered(true);
  };

  if (settingsOn) {
    return (
      <div className="settingBox">
        <h2 className="settingHeader">Settings</h2>
        <div className="AWButton">
          <p>Answer with : </p>
          <button
            onClick={() => {
              setAnswerWith((current) =>
                current === "question" ? "answer" : "question"
              );
              setRemainingCards(shuffle(cards));
              setDoneCards([]);
              setWrongCards([]);
            }}
          >
            {answerWith === "question" ? "answer" : "question"}
          </button>
        </div>
        <button className="restartButton" onClick={handleRestart}>
          Start Over
        </button>
        <button className="exitButton" onClick={handleExit}>
          Exit
        </button>
        <button className="backButton" onClick={() => setSettingsOn(false)}>
          Back
        </button>
      </div>
    );
  }

  if (mastered) {
    return (
      <div className="masteredBox">
        {roundRecords.map((record, index) => (
          <div className="roundWrapper" key={index}>
            <h2 className="round">Round {index + 1}</h2>
            <p className="record">
              record {record} / {TotalCards}
            </p>
          </div>
        ))}
        <div className="roundWrapper">
          <h2 className="round">Round {roundRecords.length + 1}</h2>
          <p className="record">
            record {TotalCards} / {TotalCards}
          </p>
        </div>
        <button className="restartButton" onClick={handleRestart}>
          Start Over
        </button>
        <button className="exitButton" onClick={handleExit}>
          Exit
        </button>
      </div>
    );
  }

  if (finished) {
    return (
      <div className="finishedBox">
        <h2 className="round">Round {roundRecords.length + 1}</h2>
        <p className="record">
          record {doneCards.length} / {TotalCards}
        </p>
        <button className="nextButton" onClick={handleNextRound}>
          Next
        </button>
      </div>
    );
  }

  if (answered) {
    return (
      <div className="workingScreen">
        <div className="leftSection">
          <div className="prgBar">
            <p>
              Remaining: {remainingCards.length} / {TotalCards}
            </p>
            <progress value={remainingCards.length} max={TotalCards} />
          </div>
          <div className="prgBar">
            <p>
              Correct: {doneCards.length} / {TotalCards}
            </p>
            <progress value={doneCards.length} max={TotalCards} />
          </div>
          <div className="prgBar">
            <p>
              Incorrect: {wrongCards.length} / {TotalCards}
            </p>
            <progress value={wrongCards.length} max={TotalCards} />
          </div>
          <button className="settingButton" onClick={() => setSettingsOn(true)}>
            Settings
          </button>
        </div>

        <div className="rightSection">
          <span>{displayedText()}</span>
          <div>
            <p>Answer:</p>
            <span>{answerText()}</span>
          </div>
          <div>
            <p>Your response:</p>
            <span>{answer}</span>
          </div>
          <button className="nextButton" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="workingScreen">
      <div className="leftSection">
        <div className="prgBar">
          <p>
            Remaining: {remainingCards.length} / {TotalCards}
          </p>
          <progress value={remainingCards.length} max={TotalCards} />
        </div>
        <div className="prgBar">
          <p>
            Correct: {doneCards.length} / {TotalCards}
          </p>
          <progress value={doneCards.length} max={TotalCards} />
        </div>
        <div className="prgBar">
          <p>
            Incorrect: {wrongCards.length} / {TotalCards}
          </p>
          <progress value={wrongCards.length} max={TotalCards} />
        </div>

        <button className="settingButton" onClick={() => setSettingsOn(true)}>
          Settings
        </button>
      </div>
      <div className="rightSection">
        <span className="keyWord">{displayedText()}</span>
        <input
          className="answerInput"
          type="text"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleAnswer();
          }}
        />
        <button className="answerButton" onClick={handleAnswer}>
          Next
        </button>
      </div>
    </div>
  );
};

export default WriteStudy;
