import React, { useEffect, useState } from "react";
import "./homePage.css";
import { NavLink, useNavigate } from "react-router-dom";
import get_all_cardsets from "../../functions/API/get_all_cardsets";
import { CardsetGet } from "../../interfaces/CardsetGet";
import { ENDPOINTS } from "../../endpoint";
import get_viewed_cardsets from "../../functions/API/get_viewed_cardsets";

const HomePage: React.FC = () => {
  const navigator = useNavigate();
  const [cardsets, setCardsets] = useState<CardsetGet[]>();
  const [viewedSets, setViewedSets] = useState<CardsetGet[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCardsets = async () => {
      try {
        const data = await get_all_cardsets();

        if (data === null) {
          console.log("Need to log in");
        } else {
          setCardsets(data);
        }
      } catch (error) {
        console.error("Error fetching cardsets:", error);
        setCardsets([]);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchViewedSets = () => {
      const viewedSets = JSON.parse(localStorage.getItem("recentlyViewed") ?? "[]");
      get_viewed_cardsets(viewedSets).then((data) => {
        if (data === null) {
          console.log("Need to log in");
        } else {
          setViewedSets(data);
        }
      });
    };

    fetchCardsets();
    fetchViewedSets();
  }, []);

  const handleStudyClick = (cardsetId: string) => {
    navigator(`/study/?c=${cardsetId}`);
  };

  return (
    <div>
      {isLoading ? (
        <p className="LoadingText">Loading...</p>
      ) : (
        <div className="CardShelfContainer">
          <NavLink
            to={ENDPOINTS.CREATE_SET}
            className="newCardSet"
            title="Create new Cardset"
          >
            Create New Cardset
          </NavLink>
          <div className="Divider"></div>
          <div className="rowContainer">
            <p className="CardsetHeader">Recent Cardsets</p>
            <div className="CardsetRow">
              {viewedSets && viewedSets.length > 0 ? (
                viewedSets.map((item, index) => (
                  <div
                    className="CardsetBox"
                    key={index}
                    onClick={() => handleStudyClick(item.id)}
                  >
                    {item.title}
                  </div>
                ))
              ) : (
                <p className="NoCardsetsMessage">No cardsets found</p>
              )}
            </div>
          </div>
          <div className="Divider"></div>
          <div className="rowContainer">
            <p className="CardsetHeader">All Cardsets</p>
            <div className="CardsetRow">
              {cardsets && cardsets.length > 0 ? (
                cardsets.map((item, index) => (
                  <div
                    className="CardsetBox"
                    key={index}
                    onClick={() => handleStudyClick(item.id)}
                  >
                    {item.title}
                  </div>
                ))
              ) : (
                <p className="NoCardsetsMessage">No cardsets found</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
