import { API_URL_BASE } from "./api_conn";
import { Nickname } from "../../interfaces/nickname";
import refreshToken from "./refresh_token";
import { getCookie } from "typescript-cookie";

const thisFetch = (formData: Nickname) => {
  const url = `${API_URL_BASE}/user/`;
  const access_token = getCookie("access_token");

  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token || ""}`,
    },
    body: JSON.stringify(formData),
  });
};

const updateUser = async (formData: Nickname): Promise<boolean> => {
  try {
    const response = await thisFetch(formData);

    if (!response.ok) {
      if (response.status === 401) {
        // Attempt to refresh token
        const refreshed = await refreshToken();
        if (refreshed) {
          // Retry updating user data after successful token refresh
          const retryResponse = await thisFetch(formData);

          if (retryResponse.ok) {
            console.log("Successfully updated");
            return true;
          } else {
            console.error("Failed to update data after token refresh");
            return false;
          }
        } else {
          console.error("Failed to refresh token");
          return false;
        }
      } else {
        console.error(`HTTP error! Status: ${response.status}`);
        return false;
      }
    } else {
      console.log("Successfully updated");
      return true;
    }
  } catch (error) {
    console.error("Error updating user:", error);
    return false; // Return false on any error
  }
};

export default updateUser;
