import { getCookie } from "typescript-cookie";
import { API_URL_BASE } from "./api_conn";
import refreshToken from "./refresh_token";
import { CardsetAndCardsGet } from "../../interfaces/CardsetGet";

const thisFetch = (csid: string) => {
  const url = `${API_URL_BASE}/cardset/cardset_id/${csid}`;
  let access_token = getCookie("access_token");

  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token || ""}`,
    },
  });
};

const get_cardset_by_id = async (
  csid: string
): Promise<CardsetAndCardsGet | null> => {
  const response = await thisFetch(csid);

  if (!response.ok) {
    if (response.status === 401) {
      // Attempt to refresh token
      const refreshed = await refreshToken();
      if (refreshed) {
        // Retry fetching cardsets after successful token refresh
        const retryResponse = await thisFetch(csid);

        if (retryResponse.ok) {
          return await retryResponse.json();
        } else {
          console.error("Failed to fetch data after token refresh");
          return null;
        }
      } else {
        console.error("Failed to refresh token");
        return null;
      }
    } else {
      console.error(`HTTP error! Status: ${response.status}`);
      return null;
    }
  }

  return await response.json();
};

export default get_cardset_by_id;
