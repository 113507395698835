import { API_URL_BASE } from "./api_conn";

export interface validation_schema {
  email: string;
  is_valid: boolean;
  detail: string;
}

const validate_email = async (email: string) => {
  const response = await fetch(`${API_URL_BASE}/validate-email/${email}`);
  return response;
};

export default validate_email;
