import React from "react";
import "./ProfileBubble.css";
import { useNavigate } from "react-router-dom";
import dummyImage from "../../dummy/default.jpeg";

interface Props {
  loggedIn: boolean;
}

const ProfileBubble = ({ loggedIn }: Props) => {
  const navigator = useNavigate();
  if (loggedIn) {
    return (
      <div className="Bubble" onClick={() => navigator("/profile")}>
        <img
          className="ProfileImage"
          src="https://picsum.photos/200"
          alt={dummyImage}
          width={50}
          height={50}
        />
      </div>
    );
  } else {
    return (
      <div className="Bubble" onClick={() => navigator("/login")}>
        <img
          className="ProfileImage"
          src={dummyImage}
          width={50}
          height={50}
          alt="noImage"
        />
      </div>
    );
  }
};

export default ProfileBubble;
