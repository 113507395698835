import React, { useEffect, useState } from "react";
import get_all_categories from "../../functions/API/get_categories";
import refreshToken from "../../functions/API/refresh_token";
import postCardset from "../../functions/API/post_cardset";
import { Card } from "../../interfaces/CardsetAndCards";
import { useNavigate, useSearchParams } from "react-router-dom";
import { category } from "../../interfaces/category";
import "./updateSetPage.css";
import deleteCardset from "../../functions/API/delete_cardset";
import { CardsetAndCardsGet, CardsetGet } from "../../interfaces/CardsetGet";
import get_cardset_by_id from "../../functions/API/get_cardset_and_cards";
import { ENDPOINTS } from "../../endpoint";

const UpdateSetPage: React.FC = () => {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const csid = searchParams.get("c");

  const [title, setTitle] = useState<string>("");
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [categories, setCategories] = useState<Array<string>>([]);
  const [newCg, setNewCg] = useState<string>();
  const [cgToAdd, setCgToAdd] = useState<string | null>(null); // cg stand for category
  const [cards, setCards] = useState<Array<Card>>([]);
  const [valid, setValid] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [categoryPool, setCategoryPool] = useState<Array<string>>([]);

  const rmCard = (index: number) => {
    setCards((current) => {
      const tmp = [...current];
      tmp.splice(index, 1);
      return tmp;
    });
  };

  const addCard = () => {
    setCards((current) => {
      const tmp = [...current, { question: "", answer: "" }];
      return tmp;
    });
  };

  const rmCategory = (index: number) => {
    setCategories((current) => {
      const tmp = [...current];
      tmp.splice(index, 1);
      return tmp;
    });
  };

  const filter_category = (cg: string) => {
    var tmp = cg;
    tmp = tmp.replaceAll(" ", "");
    tmp = tmp.toLowerCase();
    return tmp;
  };

  const addCategory = () => {
    setCategories((current) => {
      if (current.length < 3 && cgToAdd?.trim() && !current.includes(cgToAdd.trim())) {
        if (cgToAdd === "_ADD_NEW") {
          if (newCg?.trim() && !current.includes(newCg.trim())) {
            return [...current, filter_category(newCg.trim())];
          } else {
            return current;
          }
        } else {
          return [...current, cgToAdd.trim()];
        }
      } else {
        return current;
      }
    });
  };

  const handleSubmit = async () => {
    if (csid === null) {
      console.error("no id");
      return;
    }
    const delResult = await deleteCardset(csid);
    if (!delResult) {
      console.error("Failed to delete the cardset");
      return;
    } else {
      console.log("Successfully deleted the cardset");
    }
    const result = await postCardset({
      Cardset: {
        title: title,
        set_description: description,
        categories: categories,
        is_private: isPrivate,
      },
      Cards: {
        cards: cards,
      },
    });
    if (result === null) {
      const tmp = await refreshToken();
      if (tmp === null) {
        setErrMsg("Need Log in");
      } else if (tmp) {
        handleSubmit();
      } else {
        setErrMsg("Need Log in again");
      }
    } else if (result === "not-ok") {
      console.log("something went wrong");
    } else {
      console.log("successfully Created the Cardset");
      navigator(ENDPOINTS.STUDY + "/?c=" + result);
    }
  };

  const setCardset = (data: CardsetGet) => {
    setTitle(data.title);
    setDescription(data.set_description);
    setCategories(data.categories);
    setIsPrivate(data.is_private);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const data: category[] | null = await get_all_categories();
      if (data === null) {
        console.error("cannot fetch categories");
      } else {
        setCategoryPool(data.map((item) => item.name));
      }
    };

    const fetchCardset = async () => {
      try {
        if (csid === null) {
          console.error("no id");
          return;
        }
        const data: CardsetAndCardsGet | null = await get_cardset_by_id(csid);

        if (data === null) {
          console.error("no data");
        } else {
          setCardset(data.cardset);
          setCards(data.cards);
        }
      } catch (error) {
        console.error("Error fetching cardsets:", error);
      }
    };

    fetchCategories();
    fetchCardset();
  }, [csid]);

  useEffect(() => {
    var tmp: boolean = true;
    if (title === "") {
      tmp = false;
      setErrMsg("no_title");
    } else if (cards.length < 3) {
      tmp = false;
      setErrMsg("you need at least 3 cards");
    } else {
      for (let i = 0; i < cards.length; i++) {
        const element = cards[i];
        if (element.question === "" || element.answer === "") {
          tmp = false;
          setErrMsg("some cards are empty");
        }
      }
      if (tmp) {
        setValid(true);
        setErrMsg(null);
      }
    }
  }, [title, cards.length, cards]);

  return (
    <div className="UpdateSetPage">
      <div className="topBox">
        <h1>
          Create a Cardset{" "}
          <span role="img" aria-label="pencil">
            ✏️
          </span>
        </h1>
        <div className="submitBox">
          <button className="finishButton" disabled={!valid} onClick={handleSubmit}>
            Finish
          </button>
          <div className="errorMsg">{errMsg?.replace("_", " ")}</div>
        </div>
      </div>
      <div className="CreateSetBox">
        <div className="cardsetContainer">
          <div className="InfoContainer">
            <input
              className="titleInput"
              id="title"
              placeholder="Cardset Name"
              value={title}
              onChange={(e) => {
                if (e.target.value.length < 100) {
                  setTitle(e.target.value);
                }
              }}
            />

            <input
              className="descriptionInput"
              id="description"
              placeholder="description"
              value={description}
              onChange={(e) => {
                if (e.target.value.length < 500) {
                  setDescription(e.target.value);
                }
              }}
            />
          </div>
          <div className="extraInfoContainer">
            <div className="categoryContainer">
              {categories.map((item, index) => {
                return (
                  <div className="categoryBox" key={index}>
                    {item}
                    <button
                      className="deleteCategory"
                      onClick={() => {
                        rmCategory(index);
                      }}
                    >
                      delete
                    </button>
                  </div>
                );
              })}
            </div>

            <select
              className="categorySelect"
              onChange={(e) => {
                setCgToAdd(e.target.value);
              }}
            >
              <option label="Category" value={""}></option>
              {categoryPool.map((item, index) => {
                return (
                  <option
                    className="categoryOption"
                    key={index}
                    value={item}
                    label={item}
                  />
                );
              })}
              <option
                className="categoryOption"
                label="add more categories"
                value={"_ADD_NEW"}
              ></option>
            </select>
            {cgToAdd === "_ADD_NEW" ? (
              <input
                className="newCategoryInput"
                placeholder="enter new category"
                value={newCg}
                onChange={(e) => {
                  if (e.target.value.length < 100) {
                    setNewCg(e.target.value.toLowerCase());
                  }
                }}
              ></input>
            ) : null}

            <button className="addCategory" onClick={addCategory}>
              add category
            </button>

            <div className="cbBox">
              <input
                className="isPrivateCB"
                id="isPrivate"
                type="checkbox"
                checked={isPrivate}
                onChange={() => setIsPrivate((now) => !now)}
              />
              <label htmlFor="isPrivate">Is private?</label>
            </div>
          </div>
        </div>
        <div className="cardsContainer">
          {cards.map((card, index) => {
            return (
              <div className="NewCardBox" key={index}>
                <input
                  className="questionInput"
                  type="text"
                  placeholder="Question/Term"
                  id="question"
                  value={card.question}
                  onChange={(e) => {
                    if (e.target.value.length < 150) {
                      setCards((current) => {
                        const tmp = [...current];
                        tmp[index].question = e.target.value;
                        return tmp;
                      });
                    }
                  }}
                />
                <input
                  className="answerInput"
                  type="text"
                  placeholder="Answer/Definition"
                  id="answer"
                  value={card.answer}
                  onChange={(e) => {
                    if (e.target.value.length < 150) {
                      setCards((current) => {
                        const tmp = [...current];
                        tmp[index].answer = e.target.value;
                        return tmp;
                      });
                    }
                  }}
                />
                <button
                  className="removeCard"
                  onClick={() => {
                    rmCard(index);
                  }}
                >
                  remove
                </button>
              </div>
            );
          })}

          <button className="addCardButton" onClick={addCard}>
            add card
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateSetPage;
