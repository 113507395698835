import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { removeCookie } from "typescript-cookie";
import { useAuth } from "../../AuthContext";
import getMe from "../../functions/API/get_me";
import { user } from "../../interfaces/user";
import { ENDPOINTS } from "../../endpoint";
import "./profilePage.css";

const ProfilePage: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState<string | null>(null);
  const [nickName, setNickname] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data: user | null = await getMe();
        if (data === null) {
          // Handle unauthorized or other errors
          setIsAuthenticated(false);
          return;
        }
        setEmail(data.email);
        setNickname(data.nickname);
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Error fetching profile:", error);
        setIsAuthenticated(false);
      }
    };

    if (isAuthenticated) {
      fetchProfile();
    }
  }, [isAuthenticated, setIsAuthenticated]);

  const handleLogout = () => {
    removeCookie("access_token");
    removeCookie("token_type");
    removeCookie("refresh_token");
    setIsAuthenticated(false);
  };

  return (
    <div className="profile-container">
      <h1 className="profile-header">Profile Page</h1>
      {isAuthenticated ? (
        <div className="profile-details">
          <p>Email: {email}</p>
          <p>Nickname: {nickName}</p>
          <div className="profile-actions">
            <button className="logout-button" onClick={handleLogout}>
              Log out
            </button>
            <NavLink to={ENDPOINTS.UPDATE_USER} className="update-button">
              Update Info
            </NavLink>
          </div>
        </div>
      ) : (
        <div className="login-prompt">
          <p>Please log in to view your profile.</p>
          <NavLink to={ENDPOINTS.LOGIN} className="login-button">
            Log In
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
