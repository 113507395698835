import { getCookie } from "typescript-cookie";
import { API_URL_BASE } from "./api_conn";
import refreshToken from "./refresh_token";

const thisFetch = (cardset_id: string) => {
  const url = `${API_URL_BASE}/cardsets/${cardset_id}`;
  const access_token = getCookie("access_token");

  return fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token || ""}`,
    },
  });
};

const deleteCardset = async (cardset_id: string): Promise<boolean> => {
  const response = await thisFetch(cardset_id);

  if (!response.ok) {
    if (response.status === 401) {
      // Attempt to refresh token
      const refreshed = await refreshToken();
      if (refreshed) {
        // Retry fetching cardsets after successful token refresh
        const retryResponse = await thisFetch(cardset_id);
        if (retryResponse.ok) {
          return true;
        } else if (retryResponse.status === 404) {
          console.error("Cardset not found");
          return false;
        } else if (retryResponse.status === 403) {
          console.error("No access to delete this cardset");
          return false;
        } else {
          console.error("Failed to fetch data after token refresh");
          return false;
        }
      } else {
        console.error("Failed to refresh token");
        return false;
      }
    } else if (response.status === 404) {
      console.error("Cardset not found");
      return false;
    } else if (response.status === 403) {
      console.error("No access to delete this cardset");
      return false;
    } else {
      console.error(`HTTP error! Status: ${response.status}`);
      return false;
    }
  }

  return true;
};

export default deleteCardset;
