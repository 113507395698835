import React from "react";
import "./mainFooter.css";
import { NavLink } from "react-router-dom";
import { ENDPOINTS } from "./endpoint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const MainFooter = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-links">
          <div className="footer-section">
            <h3>Navigation</h3>
            <ul>
              <li>
                <NavLink to={ENDPOINTS.HOME}>Home</NavLink>
              </li>
              <li>
                <NavLink to={ENDPOINTS.ABOUT_US}>About Us</NavLink>
              </li>
              <li>
                <NavLink to={ENDPOINTS.PRIVACY_POLICY}>Privacy Policy</NavLink>
              </li>
              <li>
                <NavLink to={ENDPOINTS.TERMS_OF_SERVICE}>Terms of Service</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Account</h3>
            <ul>
              <li>
                <a href="/profile">Profile</a>
              </li>
              <li>
                <a href="/login">Login</a>
              </li>
              <li>
                <a href="/signup">Sign Up</a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Follow Us</h3>
            <div className="social-media-icons">
              <a
                href="https://www.linkedin.com/in/hyoseo-lee-564b95246/"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faLinkedin} size="xl" />
              </a>
              <a
                href="https://github.com/hyoseo837"
                target="_blank"
                rel="noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faGithub} size="xl" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Flashcard Study App. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
