import { getCookie } from "typescript-cookie";
import { API_URL_BASE } from "./api_conn";
import { CardsetAndCards } from "../../interfaces/CardsetAndCards";
import { CardsetGet } from "../../interfaces/CardsetGet";

const postCardset = async (
  formData: CardsetAndCards
): Promise<string | null> => {
  const url = `${API_URL_BASE}/cardset/`;

  const access_token = getCookie("access_token");
  if (!access_token) {
    return null;
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    return "not-ok";
  } else {
    const data: CardsetGet = await response.json();
    // console.log(data);
    return data.id;
  }
};
export default postCardset;
