import React from "react";
import "./termsOfServicePage.css";

const TermsOfServicePage: React.FC = () => {
  return (
    <div className="tos-container">
      <header className="tos-header">
        <h1>Terms of Service</h1>
        <p>Last updated: 2024/07/28</p>
      </header>
      <section className="tos-content">
        <h2>Introduction</h2>
        <p>
          Welcome to Dchup. These Terms of Service govern your use of our website and
          services. By accessing or using Dchup, you agree to be bound by these Terms. If
          you do not agree to these Terms, please do not use our services.
        </p>

        <h2>Accounts</h2>
        <p>
          When you create an account with us, you must provide us with information that is
          accurate, complete, and current at all times. Failure to do so constitutes a
          breach of the Terms, which may result in immediate termination of your account
          on our service.
        </p>

        <h2>Intellectual Property</h2>
        <p>
          The service and its original content, features, and functionality are and will
          remain the exclusive property of Dchup and its licensors. The service is
          protected by copyright, trademark, and other laws of both the United States and
          foreign countries. Our trademarks and trade dress may not be used in connection
          with any product or service without the prior written consent of Dchup.
        </p>

        <h2>User Conduct</h2>
        <p>
          You agree not to use the service for any purpose that is unlawful or prohibited
          by these Terms. You agree not to use the service in any manner that could
          damage, disable, overburden, or impair the service or interfere with any other
          party's use and enjoyment of the service.
        </p>

        <h2>Termination</h2>
        <p>
          We may terminate or suspend your account and bar access to the service
          immediately, without prior notice or liability, under our sole discretion, for
          any reason whatsoever and without limitation, including but not limited to a
          breach of the Terms.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          In no event shall Dchup, nor its directors, employees, partners, agents,
          suppliers, or affiliates, be liable for any indirect, incidental, special,
          consequential, or punitive damages, including without limitation, loss of
          profits, data, use, goodwill, or other intangible losses, resulting from (i)
          your use or inability to use the service; (ii) any unauthorized access to or use
          of our servers and/or any personal information stored therein; (iii) any
          interruption or cessation of transmission to or from the service; (iv) any bugs,
          viruses, trojan horses, or the like that may be transmitted to or through our
          service by any third party; (v) any errors or omissions in any content or for
          any loss or damage incurred as a result of the use of any content posted,
          emailed, transmitted, or otherwise made available through the service; and/or
          (vi) the defamatory, offensive, or illegal conduct of any third party.
        </p>

        <h2>Changes to These Terms</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace these Terms
          at any time. If a revision is material, we will provide at least 30 days' notice
          prior to any new terms taking effect. What constitutes a material change will be
          determined at our sole discretion.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at{" "}
          <a href="mailto:dchupSPT@gmail.com">dchupSPT@gmail.com</a>.
        </p>
      </section>
    </div>
  );
};

export default TermsOfServicePage;
