import React from "react";
import "./privacyPolicyPage.css";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="privacy-container">
      <header className="privacy-header">
        <h1>Privacy Policy</h1>
        <p>Last updated: 2024/07/28</p>
      </header>
      <section className="privacy-content">
        <h2>Introduction</h2>
        <p>
          Welcome to Dchup. We are committed to protecting your personal information and
          your right to privacy. If you have any questions or concerns about our policy,
          or our practices with regards to your personal information, please contact us at{" "}
          <a href="mailto:dchupSPT@gmail.com">dchupSPT@gmail.com</a>.
        </p>

        <h2>Information We Collect</h2>
        <p>
          We collect personal information that you voluntarily provide to us when you
          register on the platform, express an interest in obtaining information about us
          or our products and services, when you participate in activities on the platform
          (such as posting messages in our online forums or entering competitions,
          contests or giveaways) or otherwise when you contact us.
        </p>
        <ul>
          <li>
            <strong>Personal Information Provided by You:</strong> We collect names; email
            addresses; passwords; and other similar information.
          </li>
          <li>
            <strong>Information automatically collected:</strong> We automatically collect
            certain information when you visit, use or navigate the platform. This
            information does not reveal your specific identity but may include device and
            usage information, such as your IP address, browser and device
            characteristics, operating system, language preferences, referring URLs,
            device name, country, location, information about how and when you use our
            platform and other technical information.
          </li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>To facilitate account creation and logon process.</li>
          <li>To send administrative information to you.</li>
          <li>To fulfill and manage your orders.</li>
          <li>To post testimonials.</li>
          <li>Request feedback.</li>
          <li>To protect our services.</li>
          <li>To enforce our terms, conditions and policies.</li>
          <li>To respond to legal requests and prevent harm.</li>
          <li>To manage user accounts.</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>We only share and disclose your information in the following situations:</p>
        <ul>
          <li>
            <strong>Compliance with Laws:</strong> We may disclose your information where
            we are legally required to do so in order to comply with applicable law,
            governmental requests, a judicial proceeding, court order, or legal process.
          </li>
          <li>
            <strong>Vital Interests and Legal Rights:</strong> We may disclose your
            information where we believe it is necessary to investigate, prevent, or take
            action regarding potential violations of our policies, suspected fraud,
            situations involving potential threats to the safety of any person and illegal
            activities, or as evidence in litigation in which we are involved.
          </li>
        </ul>

        <h2>Security of Your Information</h2>
        <p>
          We use administrative, technical, and physical security measures to help protect
          your personal information. While we have taken reasonable steps to secure the
          personal information you provide to us, please be aware that despite our
          efforts, no security measures are perfect or impenetrable, and no method of data
          transmission can be guaranteed against any interception or other type of misuse.
        </p>

        <h2>Your Privacy Rights</h2>
        <p>
          In some regions, such as the European Economic Area, you have rights that allow
          you greater access to and control over your personal information. You may
          review, change, or terminate your account at any time.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have questions or comments about this policy, you may email us at{" "}
          <a href="mailto:dchupSPT@gmail.com">dchupSPT@gmail.com</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
