import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import login from "../../functions/API/log_in";
import { useAuth } from "../../AuthContext";
import "./loginPage.css";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const handleLogin = async () => {
    if (email !== "" && password !== "") {
      const response = await login(email, password);
      if (response) {
        setIsAuthenticated(true);
        navigate("/");
      } else {
        setErrorMsg("Wrong email or Password");
      }
    }
  };

  return (
    <div className="login-container">
      <h1 className="login-header">Login</h1>
      <label htmlFor="emailInput" className="login-label">
        Email:
      </label>
      <input
        id="emailInput"
        type="text"
        value={email}
        className="login-input"
        placeholder="Your email here"
        onChange={(e) => setEmail(e.target.value)}
      />
      <label htmlFor="pwInput" className="login-label">
        Password:
      </label>
      <input
        id="pwInput"
        type="password"
        value={password}
        className="login-input"
        placeholder="Your password here"
        onChange={(e) => setPassword(e.target.value)}
      />
      {errorMsg && <p className="error-msg">{errorMsg}</p>}
      <button type="button" className="login-button" onClick={handleLogin}>
        Log in
      </button>
      <NavLink to="/signin" className="signin-link">
        Sign in
      </NavLink>
    </div>
  );
};

export default LoginPage;
