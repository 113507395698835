export const ENDPOINTS = {
  HOME: "/",
  STUDY: "/study",
  LOGIN: "/login",
  SIGNIN: "/signin",
  PROFILE: "/profile",
  CREATE_SET: "/createSet",
  UPDATE_USER: "/updateUser",
  UPDATE_SET: "/updateSet",
  ABOUT_US: "/about",
  PRIVACY_POLICY: "/privacy",
  TERMS_OF_SERVICE: "/terms",
};
