import { SignInForm } from "../../interfaces/signInForm";
import { API_URL_BASE } from "./api_conn";

const signIn = async (formData: SignInForm): Promise<boolean | null> => {
  const url = `${API_URL_BASE}/user/`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    return false;
  } else {
    return true;
  }
};
export default signIn;
