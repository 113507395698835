import { category } from "../../interfaces/category";
import { API_URL_BASE } from "./api_conn";

const thisFetch = () => {
  const url = `${API_URL_BASE}/categories`;

  return fetch(url, {
    method: "GET",
  });
};

const get_all_categories = async (): Promise<category[] | null> => {
  const response = await thisFetch();

  if (!response.ok) {
    console.error(`HTTP error! Status: ${response.status}`);
    return null;
  }

  return await response.json();
};

export default get_all_categories;
