import { setCookie } from "typescript-cookie";
import { API_URL_BASE } from "./api_conn";

export interface TokenSchema {
  access_token: string;
  token_type: string;
  refresh_token: string;
}

const login = async (email: string, password: string): Promise<boolean> => {
  const url = `${API_URL_BASE}/token`;
  const formData = { email, password };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    return false;
  } else {
    const data: TokenSchema = await response.json();
    setCookie("access_token", data.access_token);
    setCookie("token_type", data.token_type);
    setCookie("refresh_token", data.refresh_token);
    return true;
  }
};

export default login;
