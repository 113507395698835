import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CardGet, CardsetAndCardsGet, CardsetGet } from "../../interfaces/CardsetGet";
import get_cardset_by_id from "../../functions/API/get_cardset_and_cards";
import BaseStudy from "./BaseStudy";
import WriteStudy from "./WriteStudy";
import getMe from "../../functions/API/get_me";
import { addViewedSet } from "../../functions/etc/addViewedSet";

const StudyPage = () => {
  const [searchParams] = useSearchParams();
  const [cardset, setcardset] = useState<CardsetGet>();
  const [cards, setCards] = useState<Array<CardGet>>();
  const [me, setMe] = useState<string>("uuid");
  const [mod, setMod] = useState<string>("base");
  const [error, setError] = useState<string>("");
  const csid = searchParams.get("c");

  const renderStudyMode = () => {
    if (cardset === undefined || cards === undefined) {
      return error === "" ? <p>loading...</p> : <p>{error}</p>;
    }
    switch (mod) {
      case "write":
        return <WriteStudy cardset={cardset} cards={cards} setMod={setMod} />;
      default:
        return <BaseStudy cardset={cardset} cards={cards} setMod={setMod} me={me} />;
    }
  };

  useEffect(() => {
    const fetchCardset = async () => {
      try {
        if (csid === null) {
          setError("no id");
          return;
        }
        const data: CardsetAndCardsGet | null = await get_cardset_by_id(csid);

        if (data === null) {
          setError("no data");
        } else {
          setcardset(data.cardset);
          setCards(data.cards);
          addViewedSet(data.cardset.id);
          setError("");
        }
      } catch (error) {
        console.error("Error fetching cardsets:", error);
        setError("There was an error fetching the cardset : " + error);
      }
    };
    const fetchMe = async () => {
      const tmpMe = await getMe();
      if (tmpMe == null) {
        setMe("");
      } else {
        setMe(tmpMe.id);
      }
    };

    fetchCardset();
    fetchMe();
  }, [csid]);

  return renderStudyMode();
};

export default StudyPage;
