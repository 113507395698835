import React from "react";
import "./aboutUsPage.css";
import member from "../../../dummy/member.jpeg";

const AboutUsPage: React.FC = () => {
  return (
    <div className="about-container">
      <header className="about-header">
        <h1>About Dchup</h1>
        <p>Your ultimate tool for effective learning and memorization.</p>
      </header>
      <section className="about-content">
        <h2>Our Mission</h2>
        <p>
          At Dchup, our mission is to make learning more efficient and enjoyable by
          providing an easy-to-use platform for creating, sharing, and studying digital
          flashcards. We believe that everyone deserves access to high-quality educational
          tools, and we're committed to helping students and lifelong learners achieve
          their goals.
        </p>

        <h2>Our Story</h2>
        <p>
          Dchup was founded by a team of a developer who wanted to revolutionize the way
          people learn. Frustrated by the lack of effective study tools available, we
          decided to create our own solution. Our journey began with a simple idea and has
          since evolved into a comprehensive app used by thousands of learners worldwide.
          The name "Dchup" combines "D" for digital and "chup" from the Korean word "수첩"
          meaning note, reflecting our dedication to modern, efficient learning.
        </p>

        <h2>What We Offer</h2>
        <ul>
          <li>Easy-to-create flashcards with rich text formatting.</li>
          <li>Ability to organize flashcards into sets and categories.</li>
          <li>Tools for tracking progress and performance.</li>
          <li>Community features for sharing and discovering flashcard sets.</li>
          <li>Multi-device support for studying anytime, anywhere.</li>
        </ul>

        <h2>Meet the Team</h2>
        <div className="team-members">
          <div className="team-member">
            <img src={member} alt="Team Member 1" />
            <h3>Hyoseo Lee</h3>
            <p>Founder & Developer & Designer</p>
          </div>
        </div>

        <h2>Contact Us</h2>
        <p>
          We love hearing from our users! If you have any questions, suggestions, or
          feedback, please don't hesitate to reach out to us at{" "}
          <a href="mailto:dchupSPT@gmail.com">dchupSPT@gmail.com</a>.
        </p>
      </section>
    </div>
  );
};

export default AboutUsPage;
