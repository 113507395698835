const commonPasswords: Set<string> = new Set([
  "password",
  "123456",
  "123456789",
  "12345678",
  "12345",
  "1234567",
  "qwerty",
  "abc123",
  "password1",
  "admin",
]);

function isPasswordValid(pw: string): string {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(pw);
  const hasLowerCase = /[a-z]/.test(pw);
  const hasNumber = /\d/.test(pw);
  const hasSpecialChar = /[!@#$%^&*(),.?:{}|<>]/.test(pw);

  const isNotCommon = !commonPasswords.has(pw);

  if (!hasUpperCase) {
    return "Password must contain at least one Uppercase letter";
  } else if (!hasLowerCase) {
    return "Password must contain at least one Lowercase letter";
  } else if (!hasNumber) {
    return "Password must contain at least one number.";
  } else if (!hasSpecialChar) {
    return "Password must contain at least one special character among !@#$%^&*(),.?:{}|<>";
  } else if (pw.length < minLength) {
    return "Password must be longer than 8 characters";
  } else if (!isNotCommon) {
    return "the password is too common";
  } else {
    return "ok";
  }
}

export default isPasswordValid;
