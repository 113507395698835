import { getCookie } from "typescript-cookie";
import { API_URL_BASE } from "./api_conn";
import refreshToken from "./refresh_token";
import { CardsetGet } from "../../interfaces/CardsetGet";

const thisFetch = (formData: string[]) => {
  const url = `${API_URL_BASE}/cardsets/id_list/`;
  const access_token = getCookie("access_token");

  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token || ""}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ cardset_id_list: formData }),
  });
};

const get_viewed_cardsets = async (formData: string[]): Promise<CardsetGet[] | null> => {
  const response = await thisFetch(formData);

  if (!response.ok) {
    if (response.status === 401) {
      // Attempt to refresh token
      const refreshed = await refreshToken();
      if (refreshed) {
        // Retry fetching cardsets after successful token refresh
        const retryResponse = await thisFetch(formData);

        if (retryResponse.ok) {
          return await retryResponse.json();
        } else {
          console.error("Failed to fetch data after token refresh");
          return null;
        }
      } else {
        console.error("Failed to refresh token");
        return null;
      }
    } else {
      console.error(`HTTP error! Status: ${response.status}`);
      return null;
    }
  }

  return await response.json();
};

export default get_viewed_cardsets;
