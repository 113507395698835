import React, { useState } from "react";
import { CardGet, CardsetGet } from "../../interfaces/CardsetGet";
import MainCard from "./MainCard";
import { FlashCard } from "../../classes/FlashCard";
import "./BaseStudy.css";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS } from "../../endpoint";
import deleteCardset from "../../functions/API/delete_cardset";

interface Props {
  cardset: CardsetGet;
  cards: CardGet[];
  setMod: React.Dispatch<React.SetStateAction<string>>;
  me: string;
}

const BaseStudy = (props: Props) => {
  const navigator = useNavigate();
  const { cardset, cards, me } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [reallyDelete, setReallyDelete] = useState<boolean>(false);

  const handleIncrement = () => {
    if (cards) {
      setCurrentIndex((c) => (c === cards.length - 1 ? c : c + 1));
    }
  };
  const handledecrement = () => {
    if (cards) {
      setCurrentIndex((c) => (c === 0 ? c : c - 1));
    }
  };
  const handleDelete = () => {
    deleteCardset(cardset.id).then((result) => {
      if (result) {
        navigator(ENDPOINTS.HOME);
      } else {
        alert("Failed to delete cardset");
      }
    });
  };

  return (
    <div>
      <h1 className="title">{cardset.title}</h1>
      <p className="description">{cardset.set_description}</p>
      <div className="categories">
        {cardset.categories.map((item, index) => {
          return (
            <span className="category" key={index}>
              {item}
            </span>
          );
        })}
      </div>
      <span className="createdDate">{cardset.created_date}</span>
      <button className="changeMod" onClick={() => props.setMod("write")}>
        write
      </button>
      {me === cardset.creator_id && (
        <button
          className="csUpdate"
          onClick={() => navigator(ENDPOINTS.UPDATE_SET + "/?c=" + cardset.id)}
        >
          Update
        </button>
      )}
      {me === cardset.creator_id ? (
        !reallyDelete ? (
          <button className="csDelete" onClick={() => setReallyDelete(true)}>
            Delete
          </button>
        ) : (
          <div className="reallyHolder">
            <p>Are you sure you want to delete this cardset?</p>
            <button className="really" onClick={handleDelete}>
              sure
            </button>
            <button className="notReally" onClick={() => setReallyDelete(false)}>
              cancel
            </button>
          </div>
        )
      ) : null}
      <div className="MainSection">
        <MainCard card={cards[currentIndex]} />
        <button className="prev" onClick={handledecrement}>
          {"<"}
        </button>
        <button className="next" onClick={handleIncrement}>
          {">"}
        </button>
      </div>
      <div className="fullSet">
        {cards.map((card: FlashCard, i: number) => {
          return (
            <div className="card" key={i}>
              <p>{card.question}</p>
              <p>{card.answer}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BaseStudy;
