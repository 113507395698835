import { getCookie, setCookie } from "typescript-cookie";
import { API_URL_BASE } from "./api_conn";
import { TokenSchema } from "./log_in";

const refreshToken = async (): Promise<boolean> => {
  const url = `${API_URL_BASE}/refresh-token`;
  const refresh_token = getCookie("refresh_token");

  if (!refresh_token) {
    return false; // No refresh token available, unable to refresh
  }

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${refresh_token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      return false; // Refresh token request failed
    }

    const data: TokenSchema = await response.json();

    // Update the access token in the cookie with the refreshed token
    setCookie("access_token", data.access_token);
    setCookie("token_type", data.token_type);
    setCookie("refresh_token", data.refresh_token);

    return true; // Refresh token successful
  } catch (error) {
    console.error("Error refreshing token:", error);
    return false; // Catch any unexpected errors during token refresh
  }
};

export default refreshToken;
