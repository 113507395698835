import React, { useState } from "react";
import updateUser from "../../functions/API/update_user";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS } from "../../endpoint";
import "./updateUserPage.css";

const UpdateUserPage = () => {
  const navigate = useNavigate();
  const [nickname, setNickname] = useState<string>("");

  const handleUpdate = async () => {
    const result = await updateUser({ nickname: nickname });
    if (result) {
      navigate(ENDPOINTS.PROFILE);
    }
  };

  return (
    <div className="update-container">
      <h1 className="update-header">Update User</h1>
      <label htmlFor="newNickname" className="update-label">
        Enter your new Nickname
      </label>
      <input
        id="newNickname"
        type="text"
        value={nickname}
        placeholder="Your new nickname"
        onChange={(e) => {
          setNickname(e.target.value.trim());
        }}
        className="update-input"
      />
      <div className="update-actions">
        <button
          className="updateButton"
          disabled={nickname.length > 50 || nickname.length <= 0}
          onClick={handleUpdate}
        >
          Update
        </button>
        <button className="cancel-button" onClick={() => navigate(ENDPOINTS.PROFILE)}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UpdateUserPage;
